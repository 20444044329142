.html {
	scroll-behavior: smooth;
}

@font-face {
	font-family: "Source Sans Variable";
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/SourceSansVariable-Roman.ttf") format("truetype");
}

@font-face {
	font-family: "GlitterButter";
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/GlitterButter.ttf") format("truetype");
}
@font-face {
	font-family: "Arial Bold";
	font-style: normal;
	font-weight: 800;
	src: url("./fonts/Arial\ Bold.ttf") format("truetype");
}
@font-face {
	font-family: "Gotham Black";
	font-style: normal;
	font-weight: 800;
	src: url("./fonts/Gotham-Black_A.otf") format("truetype");
}
@font-face {
	font-family: "Arial";
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/Arial.ttf") format("truetype");
}

body {
	background-color: white;
	color: "#000";
	margin: 0;
	font-family: "Source Sans Variable";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
